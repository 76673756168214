import styled from 'styled-components'
import * as G from '../globalStyle'

export const FormSection = styled.section`
  position: relative;
  width: 100%;
  padding-bottom: 50px;

  @media (min-width: ${props => props.theme.size.large}) {
    padding-bottom: 100px;
    padding-top: 100px;
  }
`

export const FormContainer = styled(G.Container)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const ContactShape1 = styled(G.Shape)`
  top: 41%;
  left: -186px;
  transform: rotate(281deg);

  svg {
    transform: scale(1);

    @media (min-width: ${props => props.theme.size.medium}) {
      transform: scale(1.6);
    }
  }
`

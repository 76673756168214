import React from 'react'
import Layout from '../components/layout'
import ContactInfo from '../components/contactInfo/contactInfo'
import ContactForm from '../components/form/contactForm'
import * as G from '../styles/globalStyle'
import * as S from '../styles/pages/contactStyle'
import * as Shape1 from '../assets/images/static/lines.svg'

export default function Contact({ data }) {
  return (
    <Layout>
      <ContactInfo></ContactInfo>
      {/* <S.FormSection>
        <S.ContactShape1>
          <Shape1 />
        </S.ContactShape1>
        <S.FormContainer>
          <G.SubHeader>Napisz do Nas</G.SubHeader>
          <ContactForm />
        </S.FormContainer>
      </S.FormSection> */}
    </Layout>
  )
}

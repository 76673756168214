import styled from 'styled-components'

export const Form = styled.form`
  margin-top: 35px;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (min-width: ${props => props.theme.size.medium}) {
    margin-top: 50px;
  }

  @media (min-width: ${props => props.theme.size.large}) {
    margin-top: 100px;
  }
`

export const Input = styled.input`
  width: 100%;
  height: 50px;
  border: 1px solid ${({ theme }) => theme.color.fourth};
  background-color: ${({ theme }) => theme.color.fifth};
  color: ${({ theme }) => theme.color.secondary};
  font-size: 16px;
  font-family: 'Book Antiqua';
  padding-left: 10px;
  padding-right: 10px;

  ::placeholder {
    color: ${({ theme }) => theme.color.fourth};
  }

  :focus {
    border: 1px solid ${({ theme }) => theme.color.fourth};
    outline: none;
  }
`

export const Cell = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 30px;
`
export const Textarea = styled.textarea`
  min-height: 350px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.color.fourth};
  background-color: ${({ theme }) => theme.color.fifth};
  color: ${({ theme }) => theme.color.secondary};
  resize: vertical;
  color: ${({ theme }) => theme.color.secondary};
  font-size: 16px;
  font-family: 'Book Antiqua';
  padding: 10px;

  ::placeholder {
    color: ${({ theme }) => theme.color.fourth};
  }

  :focus {
    border: 1px solid ${({ theme }) => theme.color.fourth};
    outline: none;
  }
`
export const SubmitButton = styled.button`
  border: none;
  padding: 20px 60px;
  font-family: 'Book Antiqua';
  display: inline-block;
  text-align: center;
  background-color: ${({ theme }) => theme.color.third};
  opacity: 1;
  border-radius: 55px;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  color: ${({ theme }) => theme.color.secondary};
  position: relative;
  transition: transform 0.3s;
  text-decoration: none;
  margin: auto;

  &:hover {
    transform: translateY(-2px);
  }

  &:active {
    opacity: 0.8;
    top: 1px;
  }

  @media (min-width: ${props => props.theme.size.large}) {
    font-size: 18px;
    padding: 23px 65px;
  }
`
export const Label = styled.label`
  display: none;
`
export const ErrorMessage = styled.div`
  position: absolute;
  bottom: -14px;
  left: 5px;
  font-size: 10px;
  color: red;
  text-transform: lowercase;
  font-family: 'Book Antiqua';
`

export const MessageContainer = styled.div`
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
`

export const Message = styled.h5`
  text-align: center;
  font-size: 22px;
`
